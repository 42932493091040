import React from "react";
import Container from "components/Container";

const ContactInfoPart = () => {
  return (
    <div className="py-[4%]">
      <Container>
        <div></div>
      </Container>
    </div>
  );
};

export default ContactInfoPart;
